var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { id: "dashboard", fluid: "", tag: "section" } },
    [
      _vm.loading
        ? _c("v-container", [
            _c(
              "div",
              { staticClass: "text-center" },
              [
                _c("v-progress-circular", {
                  attrs: {
                    indeterminate: "",
                    size: 150,
                    width: 8,
                    color: "green"
                  }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      !_vm.loading
        ? _c(
            "v-row",
            [
              _vm.metrics[_vm.M_VIRUCKA]
                ? _c(
                    "v-col",
                    {
                      staticClass: "pb-0 pt-0",
                      attrs: { cols: "12", sm: "6", md: "6", lg: "4", xl: "3" },
                      on: {
                        click: function($event) {
                          return _vm.singleMetric(_vm.metrics[_vm.M_VIRUCKA])
                        }
                      }
                    },
                    [
                      _c("base-material-stats-card", {
                        staticClass: "mb-0",
                        attrs: {
                          color: "info",
                          icon: "mdi-wallet-plus",
                          title: "Выручка по кассе",
                          value: String(_vm.metrics[_vm.M_VIRUCKA].value),
                          "sub-icon": _vm.percentsIcon(
                            _vm.metrics[_vm.M_VIRUCKA].conversion
                          ),
                          "sub-icon-color": _vm.percentsColor(
                            _vm.metrics[_vm.M_VIRUCKA].conversion
                          ),
                          "sub-text": _vm.percents(
                            _vm.metrics[_vm.M_VIRUCKA].conversion
                          )
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.metrics[_vm.M_CHECK]
                ? _c(
                    "v-col",
                    {
                      staticClass: "pb-0 pt-0",
                      attrs: { cols: "12", sm: "6", md: "6", lg: "4", xl: "3" },
                      on: {
                        click: function($event) {
                          return _vm.singleMetric(_vm.metrics[_vm.M_CHECK])
                        }
                      }
                    },
                    [
                      _vm.metrics[_vm.M_CHECK]
                        ? _c("base-material-stats-card", {
                            staticClass: "mb-0",
                            attrs: {
                              color: "success",
                              icon: "mdi-account-cash",
                              title: "Средний чек по кассе",
                              value: String(_vm.metrics[_vm.M_CHECK].value),
                              "sub-icon": _vm.percentsIcon(
                                _vm.metrics[_vm.M_CHECK].conversion
                              ),
                              "sub-icon-color": _vm.percentsColor(
                                _vm.metrics[_vm.M_CHECK].conversion
                              ),
                              "sub-text": _vm.percents(
                                _vm.metrics[_vm.M_CHECK].conversion
                              )
                            },
                            on: {
                              click: function($event) {
                                return _vm.singleMetric(
                                  _vm.metrics[_vm.M_CHECK]
                                )
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm.metrics[_vm.M_VIRUCKA_DATES]
                ? _c(
                    "v-col",
                    {
                      staticClass: "pb-0 pt-0",
                      attrs: { cols: "12", sm: "6", md: "6", lg: "4", xl: "3" },
                      on: {
                        click: function($event) {
                          return _vm.singleMetric(
                            _vm.metrics[_vm.M_VIRUCKA_DATES]
                          )
                        }
                      }
                    },
                    [
                      _c("base-material-stats-card", {
                        staticClass: "mb-0",
                        attrs: {
                          color: "info",
                          icon: "mdi-wallet-plus-outline",
                          title: "Выручка по дате",
                          value: String(_vm.metrics[_vm.M_VIRUCKA_DATES].value),
                          "sub-icon": _vm.percentsIcon(
                            _vm.metrics[_vm.M_VIRUCKA_DATES].conversion
                          ),
                          "sub-icon-color": _vm.percentsColor(
                            _vm.metrics[_vm.M_VIRUCKA_DATES].conversion
                          ),
                          "sub-text": _vm.percents(
                            _vm.metrics[_vm.M_VIRUCKA_DATES].conversion
                          )
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.metrics[_vm.M_CHECK_DATES]
                ? _c(
                    "v-col",
                    {
                      staticClass: "pb-0 pt-0",
                      attrs: { cols: "12", sm: "6", md: "6", lg: "4", xl: "3" },
                      on: {
                        click: function($event) {
                          return _vm.singleMetric(
                            _vm.metrics[_vm.M_CHECK_DATES]
                          )
                        }
                      }
                    },
                    [
                      _vm.metrics[_vm.M_CHECK_DATES]
                        ? _c("base-material-stats-card", {
                            staticClass: "mb-0",
                            attrs: {
                              color: "success",
                              icon: "mdi-account-cash-outline",
                              title: "Средний чек по дате",
                              value: String(
                                _vm.metrics[_vm.M_CHECK_DATES].value
                              ),
                              "sub-icon": _vm.percentsIcon(
                                _vm.metrics[_vm.M_CHECK_DATES].conversion
                              ),
                              "sub-icon-color": _vm.percentsColor(
                                _vm.metrics[_vm.M_CHECK_DATES].conversion
                              ),
                              "sub-text": _vm.percents(
                                _vm.metrics[_vm.M_CHECK_DATES].conversion
                              )
                            },
                            on: {
                              click: function($event) {
                                return _vm.singleMetric(
                                  _vm.metrics[_vm.M_CHECK_DATES]
                                )
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm.metrics[_vm.M_ABONS]
                ? _c(
                    "v-col",
                    {
                      staticClass: "pb-0 pt-0",
                      attrs: { cols: "12", sm: "6", md: "6", lg: "4", xl: "3" },
                      on: {
                        click: function($event) {
                          return _vm.singleMetric(_vm.metrics[_vm.M_ABONS])
                        }
                      }
                    },
                    [
                      _vm.metrics[_vm.M_ABONS]
                        ? _c("base-material-stats-card", {
                            staticClass: "mb-0",
                            attrs: {
                              color: "primary",
                              icon: "mdi-sale",
                              title: "Абонементов",
                              value: String(_vm.metrics[_vm.M_ABONS].value),
                              "sub-icon": _vm.percentsIcon(
                                _vm.metrics[_vm.M_ABONS].conversion
                              ),
                              "sub-icon-color": _vm.percentsColor(
                                _vm.metrics[_vm.M_ABONS].conversion
                              ),
                              "sub-text": _vm.percents(
                                _vm.metrics[_vm.M_ABONS].conversion
                              )
                            },
                            on: {
                              click: function($event) {
                                return _vm.singleMetric(
                                  _vm.metrics[_vm.M_ABONS]
                                )
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm.metrics[_vm.M_CLIENTS]
                ? _c(
                    "v-col",
                    {
                      staticClass: "pb-0 pt-0",
                      attrs: { cols: "12", sm: "6", md: "6", lg: "4", xl: "3" },
                      on: {
                        click: function($event) {
                          return _vm.singleMetric(_vm.metrics[_vm.M_CLIENTS])
                        }
                      }
                    },
                    [
                      _vm.metrics[_vm.M_CLIENTS]
                        ? _c("base-material-stats-card", {
                            staticClass: "mb-0",
                            attrs: {
                              color: "teal",
                              icon: "mdi-human-child",
                              title: "Клиентов",
                              value: String(_vm.metrics[_vm.M_CLIENTS].value),
                              "sub-icon": _vm.percentsIcon(
                                _vm.metrics[_vm.M_CLIENTS].conversion
                              ),
                              "sub-icon-color": _vm.percentsColor(
                                _vm.metrics[_vm.M_CLIENTS].conversion
                              ),
                              "sub-text": _vm.percents(
                                _vm.metrics[_vm.M_CLIENTS].conversion
                              )
                            },
                            on: {
                              click: function($event) {
                                return _vm.singleMetric(
                                  _vm.metrics[_vm.M_CLIENTS]
                                )
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm.metrics[_vm.M_LOST]
                ? _c(
                    "v-col",
                    {
                      staticClass: "pb-0 pt-0",
                      attrs: { cols: "12", sm: "6", md: "6", lg: "4", xl: "3" },
                      on: {
                        click: function($event) {
                          return _vm.singleMetric(_vm.metrics[_vm.M_LOST])
                        }
                      }
                    },
                    [
                      _vm.metrics[_vm.M_LOST]
                        ? _c("base-material-stats-card", {
                            staticClass: "mb-0",
                            attrs: {
                              color: "error",
                              icon: "mdi-account-multiple-remove",
                              title: "Утраченных клиентов",
                              value: String(_vm.metrics[_vm.M_LOST].value),
                              "sub-icon": _vm.percentsIcon(
                                _vm.metrics[_vm.M_LOST].conversion
                              ),
                              "sub-icon-color": _vm.percentsColor(
                                _vm.metrics[_vm.M_LOST].conversion
                              ),
                              "sub-text": _vm.percents(
                                _vm.metrics[_vm.M_LOST].conversion
                              )
                            },
                            on: {
                              click: function($event) {
                                return _vm.singleMetric(_vm.metrics[_vm.M_LOST])
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm.metrics[_vm.M_ZAPOLNEN]
                ? _c(
                    "v-col",
                    {
                      staticClass: "pb-0 pt-0",
                      attrs: { cols: "12", sm: "6", md: "6", lg: "4", xl: "3" },
                      on: {
                        click: function($event) {
                          return _vm.singleMetric(_vm.metrics[_vm.M_ZAPOLNEN])
                        }
                      }
                    },
                    [
                      _vm.metrics[_vm.M_ZAPOLNEN]
                        ? _c("base-material-stats-card", {
                            staticClass: "mb-0",
                            attrs: {
                              color: "orange",
                              icon: "mdi-percent",
                              title: "Заполненность групп",
                              value: String(
                                _vm.metrics[_vm.M_ZAPOLNEN].value + "%"
                              ),
                              "sub-icon": _vm.percentsIcon(
                                _vm.metrics[_vm.M_ZAPOLNEN].conversion
                              ),
                              "sub-icon-color": _vm.percentsColor(
                                _vm.metrics[_vm.M_ZAPOLNEN].conversion
                              ),
                              "sub-text": _vm.percents(
                                _vm.metrics[_vm.M_ZAPOLNEN].conversion
                              )
                            },
                            on: {
                              click: function($event) {
                                return _vm.singleMetric(
                                  _vm.metrics[_vm.M_ZAPOLNEN]
                                )
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm.metrics[_vm.M_ENTRIES]
                ? _c(
                    "v-col",
                    {
                      staticClass: "pb-0 pt-0",
                      attrs: { cols: "12", sm: "6", md: "6", lg: "4", xl: "3" },
                      on: {
                        click: function($event) {
                          return _vm.singleMetric(_vm.metrics[_vm.M_ENTRIES])
                        }
                      }
                    },
                    [
                      _vm.metrics[_vm.M_ENTRIES]
                        ? _c("base-material-stats-card", {
                            staticClass: "mb-0",
                            attrs: {
                              color: "secondary",
                              icon: "mdi-email-plus",
                              title: "Новых заявок на обучение",
                              value: String(_vm.metrics[_vm.M_ENTRIES].value),
                              "sub-icon": _vm.percentsIcon(
                                _vm.metrics[_vm.M_ENTRIES].conversion
                              ),
                              "sub-icon-color": _vm.percentsColor(
                                _vm.metrics[_vm.M_ENTRIES].conversion
                              ),
                              "sub-text": _vm.percents(
                                _vm.metrics[_vm.M_ENTRIES].conversion
                              )
                            },
                            on: {
                              click: function($event) {
                                return _vm.singleMetric(
                                  _vm.metrics[_vm.M_ENTRIES]
                                )
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm.metrics[_vm.M_TEACHERS]
                ? _c(
                    "v-col",
                    {
                      staticClass: "pb-0 pt-0",
                      attrs: { cols: "12", sm: "6", md: "6", lg: "4", xl: "3" },
                      on: {
                        click: function($event) {
                          return _vm.singleMetric(_vm.metrics[_vm.M_TEACHERS])
                        }
                      }
                    },
                    [
                      _vm.metrics[_vm.M_TEACHERS]
                        ? _c("base-material-stats-card", {
                            staticClass: "mb-0",
                            attrs: {
                              color: "success",
                              icon: "mdi-human-female-boy",
                              title: "Преподавателей",
                              value: String(_vm.metrics[_vm.M_TEACHERS].value),
                              "sub-icon": _vm.percentsIcon(
                                _vm.metrics[_vm.M_TEACHERS].conversion
                              ),
                              "sub-icon-color": _vm.percentsColor(
                                _vm.metrics[_vm.M_TEACHERS].conversion
                              ),
                              "sub-text": _vm.percents(
                                _vm.metrics[_vm.M_TEACHERS].conversion
                              )
                            },
                            on: {
                              click: function($event) {
                                return _vm.singleMetric(
                                  _vm.metrics[_vm.M_TEACHERS]
                                )
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm.metrics[_vm.M_GROUPS]
                ? _c(
                    "v-col",
                    {
                      staticClass: "pb-0 pt-0",
                      attrs: { cols: "12", sm: "6", md: "6", lg: "4", xl: "3" },
                      on: {
                        click: function($event) {
                          return _vm.singleMetric(_vm.metrics[_vm.M_GROUPS])
                        }
                      }
                    },
                    [
                      _vm.metrics[_vm.M_GROUPS]
                        ? _c("base-material-stats-card", {
                            staticClass: "mb-0",
                            attrs: {
                              color: "orange",
                              icon: "mdi-timetable",
                              title: "Групп",
                              value: String(_vm.metrics[_vm.M_GROUPS].value),
                              "sub-icon": _vm.percentsIcon(
                                _vm.metrics[_vm.M_GROUPS].conversion
                              ),
                              "sub-icon-color": _vm.percentsColor(
                                _vm.metrics[_vm.M_GROUPS].conversion
                              ),
                              "sub-text": _vm.percents(
                                _vm.metrics[_vm.M_GROUPS].conversion
                              )
                            },
                            on: {
                              click: function($event) {
                                return _vm.singleMetric(
                                  _vm.metrics[_vm.M_GROUPS]
                                )
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm.metrics[_vm.M_LESSONS]
                ? _c(
                    "v-col",
                    {
                      staticClass: "pb-0 pt-0",
                      attrs: { cols: "12", sm: "6", md: "6", lg: "4", xl: "3" },
                      on: {
                        click: function($event) {
                          return _vm.singleMetric(_vm.metrics[_vm.M_LESSONS])
                        }
                      }
                    },
                    [
                      _vm.metrics[_vm.M_LESSONS]
                        ? _c("base-material-stats-card", {
                            staticClass: "mb-0",
                            attrs: {
                              color: "error",
                              icon: "mdi-school",
                              title: "Занятий проведено",
                              value: String(_vm.metrics[_vm.M_LESSONS].value),
                              "sub-icon": _vm.percentsIcon(
                                _vm.metrics[_vm.M_LESSONS].conversion
                              ),
                              "sub-icon-color": _vm.percentsColor(
                                _vm.metrics[_vm.M_LESSONS].conversion
                              ),
                              "sub-text": _vm.percents(
                                _vm.metrics[_vm.M_LESSONS].conversion
                              )
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm.metrics[_vm.M_MENTIONS]
                ? _c(
                    "v-col",
                    {
                      staticClass: "pb-0 pt-0",
                      attrs: { cols: "12", sm: "6", lg: "4", xl: "3" },
                      on: {
                        click: function($event) {
                          return _vm.singleMetric(_vm.metrics[_vm.M_MENTIONS])
                        }
                      }
                    },
                    [
                      _vm.metrics[_vm.M_MENTIONS]
                        ? _c("base-material-stats-card", {
                            staticClass: "mb-0",
                            attrs: {
                              color: "teal",
                              icon: "mdi-comment-multiple-outline",
                              title: "Отзывов",
                              value: String(_vm.metrics[_vm.M_MENTIONS].value),
                              "sub-icon": _vm.percentsIcon(
                                _vm.metrics[_vm.M_MENTIONS].conversion
                              ),
                              "sub-icon-color": _vm.percentsColor(
                                _vm.metrics[_vm.M_MENTIONS].conversion
                              ),
                              "sub-text": _vm.percents(
                                _vm.metrics[_vm.M_MENTIONS].conversion
                              )
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm.metrics[_vm.M_NEW_CLIENTS]
                ? _c(
                    "v-col",
                    {
                      staticClass: "pb-0 pt-0",
                      attrs: { cols: "12", sm: "6", lg: "4", xl: "3" },
                      on: {
                        click: function($event) {
                          return _vm.singleMetric(
                            _vm.metrics[_vm.M_NEW_CLIENTS]
                          )
                        }
                      }
                    },
                    [
                      _vm.metrics[_vm.M_NEW_CLIENTS]
                        ? _c("base-material-stats-card", {
                            staticClass: "mb-0",
                            attrs: {
                              color: "teal",
                              icon: "mdi-account-multiple-plus",
                              title: "Новых клиентов",
                              value: String(
                                _vm.metrics[_vm.M_NEW_CLIENTS].value
                              ),
                              "sub-icon": _vm.percentsIcon(
                                _vm.metrics[_vm.M_NEW_CLIENTS].conversion
                              ),
                              "sub-icon-color": _vm.percentsColor(
                                _vm.metrics[_vm.M_NEW_CLIENTS].conversion
                              ),
                              "sub-text": _vm.percents(
                                _vm.metrics[_vm.M_NEW_CLIENTS].conversion
                              )
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm.metrics[_vm.M_PROBS]
                ? _c(
                    "v-col",
                    {
                      staticClass: "pb-0 pt-0",
                      attrs: { cols: "12", sm: "6", lg: "4", xl: "3" },
                      on: {
                        click: function($event) {
                          return _vm.singleMetric(_vm.metrics[_vm.M_PROBS])
                        }
                      }
                    },
                    [
                      _vm.metrics[_vm.M_PROBS]
                        ? _c("base-material-stats-card", {
                            staticClass: "mb-0",
                            attrs: {
                              color: "teal",
                              icon: "mdi-clipboard-text-clock-outline",
                              title: "Назначено пробных",
                              value: String(_vm.metrics[_vm.M_PROBS].value),
                              "sub-icon": _vm.percentsIcon(
                                _vm.metrics[_vm.M_PROBS].conversion
                              ),
                              "sub-icon-color": _vm.percentsColor(
                                _vm.metrics[_vm.M_PROBS].conversion
                              ),
                              "sub-text": _vm.percents(
                                _vm.metrics[_vm.M_PROBS].conversion
                              )
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _c("v-col", { attrs: { cols: "12" } }, [
                _c("div", { staticClass: "text--disabled" }, [
                  _vm._v(
                    "\n        * Статистика представлена за " +
                      _vm._s(_vm.yesterday) +
                      "\n      "
                  )
                ])
              ])
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-row",
        [
          _vm.pie.data
            ? _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c(
                    "base-material-card",
                    {
                      staticClass: "px-4 py-3",
                      attrs: {
                        id: "pie",
                        color: "teal",
                        icon: "mdi-chart-pie",
                        title: "Клиентские каналы"
                      }
                    },
                    [
                      _c("chartist", {
                        staticStyle: { "max-height": "250px" },
                        attrs: {
                          data: _vm.pie.data,
                          options: _vm.pie.options,
                          type: "Pie"
                        }
                      }),
                      _c("div", { staticClass: "text--disabled ml-4" }, [
                        _vm._v(
                          "\n          * Информация по клиентам привлеченным за последние 30 дней\n        "
                        )
                      ]),
                      _c("v-divider", { staticClass: "ma-3" }),
                      _vm.pie.legend
                        ? _c(
                            "div",
                            { staticClass: "px-3" },
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "ma-0",
                                  attrs: { align: "center" }
                                },
                                _vm._l(_vm.pie.legend, function(item) {
                                  return _c(
                                    "span",
                                    { key: item.id },
                                    [
                                      _c("v-avatar", {
                                        class: _vm.legendClass(item.id),
                                        attrs: { size: "12" }
                                      }),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "mr-3 font-weight-light"
                                        },
                                        [_vm._v(_vm._s(item.name))]
                                      )
                                    ],
                                    1
                                  )
                                }),
                                0
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "6" } },
            [
              !_vm.loading
                ? _c(
                    "base-material-card",
                    {
                      staticClass: "px-5 py-3",
                      attrs: {
                        color: "error",
                        icon: "mdi-human-child",
                        title: "Дни рождения учащихся в ближайшие 30 дней"
                      }
                    },
                    [
                      _c("v-data-table", {
                        attrs: {
                          loading: _vm.loading,
                          "loading-text": "Загрузка...",
                          headers: _vm.birthsHeaders,
                          "sort-by": ["birth", "name"],
                          items: _vm.clients
                        },
                        on: { "click:row": _vm.singleClient },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "item.birth",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm._f("formatBirthday")(item.birthday)
                                      ) +
                                      "\n          "
                                  )
                                ]
                              }
                            },
                            {
                              key: "item.age",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  item.age
                                    ? _c("v-chip", { attrs: { small: "" } }, [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm._f("ageTurns")(item.age)
                                            ) +
                                            " лет\n            "
                                        )
                                      ])
                                    : _vm._e()
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3420060228
                        )
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "6" } },
            [
              !_vm.loading
                ? _c(
                    "base-material-card",
                    {
                      staticClass: "px-5 py-3",
                      attrs: {
                        color: "primary",
                        icon: "mdi-human-female-boy",
                        title: "Дни рождения преподавателей в ближайшие 30 дней"
                      }
                    },
                    [
                      _c("v-data-table", {
                        attrs: {
                          loading: _vm.loading,
                          "loading-text": "Загрузка...",
                          headers: _vm.birthsHeaders,
                          "sort-by": ["birth", "name"],
                          items: _vm.teachers
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "item.birth",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm._f("formatBirthday")(item.birthday)
                                      ) +
                                      "\n          "
                                  )
                                ]
                              }
                            },
                            {
                              key: "item.age",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  item.age
                                    ? _c("v-chip", { attrs: { small: "" } }, [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm._f("ageTurns")(item.age)
                                            ) +
                                            " лет\n            "
                                        )
                                      ])
                                    : _vm._e()
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3420060228
                        )
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
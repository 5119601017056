<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <v-container v-if="loading">
      <div class="text-center">
        <v-progress-circular
          indeterminate
          :size="150"
          :width="8"
          color="green"
        />
      </div>
    </v-container>
    <v-row
      v-if="!loading"
    >
      <v-col
        v-if="metrics[M_VIRUCKA]"
        class="pb-0 pt-0"
        cols="12"
        sm="6"
        md="6"
        lg="4"
        xl="3"
        @click="singleMetric(metrics[M_VIRUCKA])"
      >
        <base-material-stats-card
          class="mb-0"
          color="info"
          icon="mdi-wallet-plus"
          title="Выручка по кассе"
          :value="String(metrics[M_VIRUCKA].value)"
          :sub-icon="percentsIcon(metrics[M_VIRUCKA].conversion)"
          :sub-icon-color="percentsColor(metrics[M_VIRUCKA].conversion)"
          :sub-text="percents(metrics[M_VIRUCKA].conversion)"
        />
      </v-col>

      <v-col
        v-if="metrics[M_CHECK]"
        class="pb-0 pt-0"
        cols="12"
        sm="6"
        md="6"
        lg="4"
        xl="3"
        @click="singleMetric(metrics[M_CHECK])"
      >
        <base-material-stats-card
          v-if="metrics[M_CHECK]"
          class="mb-0"
          color="success"
          icon="mdi-account-cash"
          title="Средний чек по кассе"
          :value="String(metrics[M_CHECK].value)"
          :sub-icon="percentsIcon(metrics[M_CHECK].conversion)"
          :sub-icon-color="percentsColor(metrics[M_CHECK].conversion)"
          :sub-text="percents(metrics[M_CHECK].conversion)"
          @click="singleMetric(metrics[M_CHECK])"
        />
      </v-col>

      <v-col
        v-if="metrics[M_VIRUCKA_DATES]"
        class="pb-0 pt-0"
        cols="12"
        sm="6"
        md="6"
        lg="4"
        xl="3"
        @click="singleMetric(metrics[M_VIRUCKA_DATES])"
      >
        <base-material-stats-card
          class="mb-0"
          color="info"
          icon="mdi-wallet-plus-outline"
          title="Выручка по дате"
          :value="String(metrics[M_VIRUCKA_DATES].value)"
          :sub-icon="percentsIcon(metrics[M_VIRUCKA_DATES].conversion)"
          :sub-icon-color="percentsColor(metrics[M_VIRUCKA_DATES].conversion)"
          :sub-text="percents(metrics[M_VIRUCKA_DATES].conversion)"
        />
      </v-col>

      <v-col
        v-if="metrics[M_CHECK_DATES]"
        class="pb-0 pt-0"
        cols="12"
        sm="6"
        md="6"
        lg="4"
        xl="3"
        @click="singleMetric(metrics[M_CHECK_DATES])"
      >
        <base-material-stats-card
          v-if="metrics[M_CHECK_DATES]"
          class="mb-0"
          color="success"
          icon="mdi-account-cash-outline"
          title="Средний чек по дате"
          :value="String(metrics[M_CHECK_DATES].value)"
          :sub-icon="percentsIcon(metrics[M_CHECK_DATES].conversion)"
          :sub-icon-color="percentsColor(metrics[M_CHECK_DATES].conversion)"
          :sub-text="percents(metrics[M_CHECK_DATES].conversion)"
          @click="singleMetric(metrics[M_CHECK_DATES])"
        />
      </v-col>

      <v-col
        v-if="metrics[M_ABONS]"
        class="pb-0 pt-0"
        cols="12"
        sm="6"
        md="6"
        lg="4"
        xl="3"
        @click="singleMetric(metrics[M_ABONS])"
      >
        <base-material-stats-card
          v-if="metrics[M_ABONS]"
          class="mb-0"
          color="primary"
          icon="mdi-sale"
          title="Абонементов"
          :value="String(metrics[M_ABONS].value)"
          :sub-icon="percentsIcon(metrics[M_ABONS].conversion)"
          :sub-icon-color="percentsColor(metrics[M_ABONS].conversion)"
          :sub-text="percents(metrics[M_ABONS].conversion)"
          @click="singleMetric(metrics[M_ABONS])"
        />
      </v-col>

      <v-col
        v-if="metrics[M_CLIENTS]"
        class="pb-0 pt-0"
        cols="12"
        sm="6"
        md="6"
        lg="4"
        xl="3"
        @click="singleMetric(metrics[M_CLIENTS])"
      >
        <base-material-stats-card
          v-if="metrics[M_CLIENTS]"
          class="mb-0"
          color="teal"
          icon="mdi-human-child"
          title="Клиентов"
          :value="String(metrics[M_CLIENTS].value)"
          :sub-icon="percentsIcon(metrics[M_CLIENTS].conversion)"
          :sub-icon-color="percentsColor(metrics[M_CLIENTS].conversion)"
          :sub-text="percents(metrics[M_CLIENTS].conversion)"
          @click="singleMetric(metrics[M_CLIENTS])"
        />
      </v-col>

      <v-col
        v-if="metrics[M_LOST]"
        class="pb-0 pt-0"
        cols="12"
        sm="6"
        md="6"
        lg="4"
        xl="3"
        @click="singleMetric(metrics[M_LOST])"
      >
        <base-material-stats-card
          v-if="metrics[M_LOST]"
          class="mb-0"
          color="error"
          icon="mdi-account-multiple-remove"
          title="Утраченных клиентов"
          :value="String(metrics[M_LOST].value)"
          :sub-icon="percentsIcon(metrics[M_LOST].conversion)"
          :sub-icon-color="percentsColor(metrics[M_LOST].conversion)"
          :sub-text="percents(metrics[M_LOST].conversion)"
          @click="singleMetric(metrics[M_LOST])"
        />
      </v-col>

      <v-col
        v-if="metrics[M_ZAPOLNEN]"
        class="pb-0 pt-0"
        cols="12"
        sm="6"
        md="6"
        lg="4"
        xl="3"
        @click="singleMetric(metrics[M_ZAPOLNEN])"
      >
        <base-material-stats-card
          v-if="metrics[M_ZAPOLNEN]"
          class="mb-0"
          color="orange"
          icon="mdi-percent"
          title="Заполненность групп"
          :value="String(metrics[M_ZAPOLNEN].value + '%')"
          :sub-icon="percentsIcon(metrics[M_ZAPOLNEN].conversion)"
          :sub-icon-color="percentsColor(metrics[M_ZAPOLNEN].conversion)"
          :sub-text="percents(metrics[M_ZAPOLNEN].conversion)"
          @click="singleMetric(metrics[M_ZAPOLNEN])"
        />
      </v-col>

      <v-col
        v-if="metrics[M_ENTRIES]"
        class="pb-0 pt-0"
        cols="12"
        sm="6"
        md="6"
        lg="4"
        xl="3"
        @click="singleMetric(metrics[M_ENTRIES])"
      >
        <base-material-stats-card
          v-if="metrics[M_ENTRIES]"
          class="mb-0"
          color="secondary"
          icon="mdi-email-plus"
          title="Новых заявок на обучение"
          :value="String(metrics[M_ENTRIES].value)"
          :sub-icon="percentsIcon(metrics[M_ENTRIES].conversion)"
          :sub-icon-color="percentsColor(metrics[M_ENTRIES].conversion)"
          :sub-text="percents(metrics[M_ENTRIES].conversion)"
          @click="singleMetric(metrics[M_ENTRIES])"
        />
      </v-col>

      <v-col
        v-if="metrics[M_TEACHERS]"
        class="pb-0 pt-0"
        cols="12"
        sm="6"
        md="6"
        lg="4"
        xl="3"
        @click="singleMetric(metrics[M_TEACHERS])"
      >
        <base-material-stats-card
          v-if="metrics[M_TEACHERS]"
          class="mb-0"
          color="success"
          icon="mdi-human-female-boy"
          title="Преподавателей"
          :value="String(metrics[M_TEACHERS].value)"
          :sub-icon="percentsIcon(metrics[M_TEACHERS].conversion)"
          :sub-icon-color="percentsColor(metrics[M_TEACHERS].conversion)"
          :sub-text="percents(metrics[M_TEACHERS].conversion)"
          @click="singleMetric(metrics[M_TEACHERS])"
        />
      </v-col>

      <v-col
        v-if="metrics[M_GROUPS]"
        class="pb-0 pt-0"
        cols="12"
        sm="6"
        md="6"
        lg="4"
        xl="3"
        @click="singleMetric(metrics[M_GROUPS])"
      >
        <base-material-stats-card
          v-if="metrics[M_GROUPS]"
          class="mb-0"
          color="orange"
          icon="mdi-timetable"
          title="Групп"
          :value="String(metrics[M_GROUPS].value)"
          :sub-icon="percentsIcon(metrics[M_GROUPS].conversion)"
          :sub-icon-color="percentsColor(metrics[M_GROUPS].conversion)"
          :sub-text="percents(metrics[M_GROUPS].conversion)"
          @click="singleMetric(metrics[M_GROUPS])"
        />
      </v-col>

      <v-col
        v-if="metrics[M_LESSONS]"
        class="pb-0 pt-0"
        cols="12"
        sm="6"
        md="6"
        lg="4"
        xl="3"
        @click="singleMetric(metrics[M_LESSONS])"
      >
        <base-material-stats-card
          v-if="metrics[M_LESSONS]"
          class="mb-0"
          color="error"
          icon="mdi-school"
          title="Занятий проведено"
          :value="String(metrics[M_LESSONS].value)"
          :sub-icon="percentsIcon(metrics[M_LESSONS].conversion)"
          :sub-icon-color="percentsColor(metrics[M_LESSONS].conversion)"
          :sub-text="percents(metrics[M_LESSONS].conversion)"
        />
      </v-col>

      <v-col
        v-if="metrics[M_MENTIONS]"
        class="pb-0 pt-0"
        cols="12"
        sm="6"
        lg="4"
        xl="3"
        @click="singleMetric(metrics[M_MENTIONS])"
      >
        <base-material-stats-card
          v-if="metrics[M_MENTIONS]"
          class="mb-0"
          color="teal"
          icon="mdi-comment-multiple-outline"
          title="Отзывов"
          :value="String(metrics[M_MENTIONS].value)"
          :sub-icon="percentsIcon(metrics[M_MENTIONS].conversion)"
          :sub-icon-color="percentsColor(metrics[M_MENTIONS].conversion)"
          :sub-text="percents(metrics[M_MENTIONS].conversion)"
        />
      </v-col>

      <v-col
        v-if="metrics[M_NEW_CLIENTS]"
        class="pb-0 pt-0"
        cols="12"
        sm="6"
        lg="4"
        xl="3"
        @click="singleMetric(metrics[M_NEW_CLIENTS])"
      >
        <base-material-stats-card
          v-if="metrics[M_NEW_CLIENTS]"
          class="mb-0"
          color="teal"
          icon="mdi-account-multiple-plus"
          title="Новых клиентов"
          :value="String(metrics[M_NEW_CLIENTS].value)"
          :sub-icon="percentsIcon(metrics[M_NEW_CLIENTS].conversion)"
          :sub-icon-color="percentsColor(metrics[M_NEW_CLIENTS].conversion)"
          :sub-text="percents(metrics[M_NEW_CLIENTS].conversion)"
        />
      </v-col>

      <v-col
        v-if="metrics[M_PROBS]"
        class="pb-0 pt-0"
        cols="12"
        sm="6"
        lg="4"
        xl="3"
        @click="singleMetric(metrics[M_PROBS])"
      >
        <base-material-stats-card
          v-if="metrics[M_PROBS]"
          class="mb-0"
          color="teal"
          icon="mdi-clipboard-text-clock-outline"
          title="Назначено пробных"
          :value="String(metrics[M_PROBS].value)"
          :sub-icon="percentsIcon(metrics[M_PROBS].conversion)"
          :sub-icon-color="percentsColor(metrics[M_PROBS].conversion)"
          :sub-text="percents(metrics[M_PROBS].conversion)"
        />
      </v-col>

      <v-col
        cols="12"
      >
        <div class="text--disabled">
          * Статистика представлена за {{ yesterday }}
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        v-if="pie.data"
        cols="12"
        md="6"
      >
        <base-material-card
          id="pie"
          color="teal"
          icon="mdi-chart-pie"
          title="Клиентские каналы"
          class="px-4 py-3"
        >
          <chartist
            :data="pie.data"
            :options="pie.options"
            type="Pie"
            style="max-height: 250px;"
          />

          <div class="text--disabled ml-4">
            * Информация по клиентам привлеченным за последние 30 дней
          </div>

          <v-divider class="ma-3" />

          <div
            v-if="pie.legend"
            class="px-3"
          >
            <!-- <div class="body-2 text-uppercase grey--text font-weight-bold mb-3">
              Legend
            </div> -->

            <v-row
              align="center"
              class="ma-0"
            >
              <span
                v-for="item in pie.legend"
                :key="item.id"
              >
                <v-avatar
                  :class="legendClass(item.id)"
                  size="12"
                />

                <span class="mr-3 font-weight-light">{{ item.name }}</span>

              </span>
            </v-row>
          </div>
        </base-material-card>
      </v-col>

      <v-col
        cols="12"
        md="6"
      >
        <base-material-card
          v-if="!loading"
          color="error"
          icon="mdi-human-child"
          title="Дни рождения учащихся в ближайшие 30 дней"
          class="px-5 py-3"
        >
          <v-data-table
            :loading="loading"
            loading-text="Загрузка..."
            :headers="birthsHeaders"
            :sort-by="['birth', 'name']"
            :items="clients"
            @click:row="singleClient"
          >
            <template v-slot:item.birth="{ item }">
              {{ item.birthday | formatBirthday }}
            </template>
            <template v-slot:item.age="{ item }">
              <v-chip
                v-if="item.age"
                small
              >
                {{ item.age | ageTurns }} лет
              </v-chip>
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>

      <v-col
        cols="12"
        md="6"
      >
        <base-material-card
          v-if="!loading"
          color="primary"
          icon="mdi-human-female-boy"
          title="Дни рождения преподавателей в ближайшие 30 дней"
          class="px-5 py-3"
        >
          <v-data-table
            :loading="loading"
            loading-text="Загрузка..."
            :headers="birthsHeaders"
            :sort-by="['birth', 'name']"
            :items="teachers"
          >
            <template v-slot:item.birth="{ item }">
              {{ item.birthday | formatBirthday }}
            </template>
            <template v-slot:item.age="{ item }">
              <v-chip
                v-if="item.age"
                small
              >
                {{ item.age | ageTurns }} лет
              </v-chip>
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import moment from 'moment'
  import metricsApi from '../services/MetricsApi'
  import teachersApi from '../services/TeachersApi'
  import clientsApi from '../services/ClientsApi'
  import { hasRole, OfficeAdmin, OfficeOwner } from '../services/jwt.service'

  export default {
    name: 'DashboardDashboard',
    filters: {
      ageTurns (age) {
        return age + 1
      },
      formatBirthday: function (date) {
        return moment(date).format('DD MMMM')
      },
    },
    data () {
      return {
        metaInfo: {
          title: 'Ваш центр',
        },
        loading: false,
        erroe: null,
        M_ABONS: 1,
        M_VIRUCKA: 2,
        M_CLIENTS: 3,
        M_CHECK: 4,
        M_VMESTIM: 5,
        M_ZAPOLNEN: 6,
        M_VIRUCKA_DATES: 7,
        M_CHECK_DATES: 8,
        M_TEACHERS: 9,
        M_GROUPS: 10,
        M_ENTRIES: 11,
        M_LESSONS: 12,
        M_MENTIONS: 13,
        M_LOST: 14,
        M_NEW_CLIENTS: 15,
        M_PROBS: 16,
        teachers: [],
        clients: [],
        birthsHeaders: [
          {
            sortable: true,
            text: 'Id',
            value: 'id',
          },
          {
            sortable: true,
            text: 'Имя',
            value: 'name',
          },
          {
            sortable: true,
            text: 'День рождения',
            value: 'birth',
          },
          {
            sortable: true,
            text: 'Исполняется',
            value: 'age',
          },
        ],

        metrics: [],
        channelsStat: {},

        yesterday: null,

        pie: {
          data: null,
          options: {
            labelInterpolationFnc: (value) => {
              return Math.round((value * 100) / this.pieTotal) + '% (' + value + ')'
            },
          },
          legend: null,
          legendClasses: null,
        },
        pieTotal: null,
      }
    },

    computed: {},
    mounted () {
      document.title = 'Ваш центр | ' + process.env.VUE_APP_NAME
      moment.locale('ru')
      // this.loading = true
      let periodId = moment().add(-1, 'days').format('YYYY-MM-DD')
      this.yesterday = moment().add(-1, 'days').format('DD MMMM YYYY')

      if (hasRole(OfficeOwner)) {
        metricsApi // метрики
          .fetchMetricsByDate('day', periodId)
          .then(response => {
            if (response) {
              let metrs = []
              response.forEach(function (metric) {
                metrs[metric.type_id] = metric
              })
              this.metrics = metrs
            }
          })
          .catch(error => {
            this.error = this.pretty(error.response.data)
            this.loading = false
          })

        clientsApi // статистика по каналам
          .fetchClientChannelsStat(30)
          .then(response => {
            this.pieTotal = response.summary.total
            if (this.pieTotal > 0) {
              this.pie.data = response.data
              this.pie.legend = response.legend
            }
            this.pie.legendClasses = response.legendClasses
          })
          .catch(error => {
            this.error = this.pretty(error.response.data)
            this.loading = false
          })
      }

      clientsApi
        .fetchBirthdaysClients()
        .then(response => {
          this.clients = response
        })
        .catch(error => {
          this.error = this.pretty(error.response.data)
          this.loading = false
        })

      teachersApi
        .fetchBirthdaysTeachers()
        .then(response => {
          this.teachers = response
        })
        .catch(error => {
          this.error = this.pretty(error.response.data)
          this.loading = false
        })
    },

    methods: {
      percents (value) {
        if (value == null) return '0%'
        if (value > 0) { return '+' + value + '%' }
        if (value < 0) { return value + '%' }
        if (value === 0) return '0%'
      },
      percentsColor (value) {
        if (value == null) return 'gray'
        if (value > 0) { return 'green' }
        if (value < 0) { return 'red' }
        if (value === 0) { return 'gray' }
      },

      percentsIcon (value) {
        if (value == null) return ''
        if (value > 0) { return 'mdi-arrow-up' }
        if (value < 0) { return 'mdi-arrow-down' }
        if (value === 0) { return '' }
      },

      singleMetric (metric) {
        console.log('click')
        console.log(metric)
        if (metric && metric.type_id) { this.$router.push('/metric/' + metric.type_id) }
      },

      singleClient (client) {
        this.$router.push('/client/' + client.id)
      },

      pretty (value) {
        if (value.message) {
          return value.message
        }
        return JSON.stringify(value, null, 2)
      },

      legendClass (id) {
        if (id && this.pie.legendClasses) {
          return 'mr-1 ' + this.pie.legendClasses[id].className
        }
      },
    },
  }
</script>

<style>
  .v-card--material-stats {
    cursor: pointer;
  }

  .v-data-table tr {
    cursor: pointer;
  }

  .pieColor-1 {
    fill: #f44336 !important;
    background-color: #f44336 !important;
  }
  .pieColor-2 {
    fill: #90caf9 !important;
    background-color: #90caf9 !important;
  }
  .pieColor-3 {
    fill: #4caf50 !important;
    background-color: #4caf50 !important;
  }
  .pieColor-4 {
    fill: #eeff41 !important;
    background-color: #eeff41 !important;
  }
  .pieColor-5 {
    fill: #aa00ff !important;
    background-color: #aa00ff !important;
  }
  .pieColor-6 {
    fill: #ffb74d !important;
    background-color: #ffb74d !important;
  }
  .pieColor-7 {
    fill: #b388ff !important;
    background-color: #b388ff !important;
  }
  .pieColor-8 {
    fill: #e91e63 !important;
    background-color: #e91e63 !important;
  }
  .pieColor-9 {
    fill: #3f51b5 !important;
    background-color: #3f51b5 !important;
  }
  .pieColor-10 {
    fill: #00bcd4 !important;
    background-color: #00bcd4 !important;
  }
  .pieColor-11 {
    fill: #006064 !important;
    background-color: #006064 !important;
  }
  .pieColor-12 {
    fill: #a7ffeb !important;
    background-color: #a7ffeb !important;
  }
  .pieColor-13 {
    fill: #9c27b0 !important;
    background-color: #9c27b0 !important;
  }
  .pieColor-14 {
    fill: #c0ca33 !important;
    background-color: #c0ca33 !important;
  }
  .pieColor-15 {
    fill: #4a148c !important;
    background-color: #4a148c !important;
  }
  .pieColor-16 {
    fill: #f57f17 !important;
    background-color: #f57f17 !important;
  }
  .pieColor-17 {
    fill: #ff5722 !important;
    background-color: #ff5722 !important;
  }
  .pieColor-18 {
    fill: #795548 !important;
    background-color: #795548 !important;
  }
  .pieColor-19 {
    fill: #757575 !important;
    background-color: #757575 !important;
  }
  .pieColor-20 {
    fill: #607d8b !important;
    background-color: #607d8b !important;
  }
  .pieColor-21 {
    fill: #673ab7 !important;
    background-color: #673ab7 !important;
  }
  .pieColor-22 {
    fill: #fbe9e7 !important;
    background-color: #fbe9e7 !important;
  }
  .pieColor-23 {
    fill: #5d4037 !important;
    background-color: #5d4037 !important;
  }
  .pieColor-24 {
    fill: #f4ff81 !important;
    background-color: #f4ff81 !important;
  }
  .pieColor-25 {
    fill: #76ff03 !important;
    background-color: #76ff03 !important;
  }
  .pieColor-26 {
    fill: #d32f2f !important;
    background-color: #d32f2f !important;
  }
</style>
